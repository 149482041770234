/* Imports */
import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

class Forms {
    constructor() {
        document.addEventListener('DOMContentLoaded', (event) => {
            this.initForms();
        });
    }

    initForms() {
        const formieInstance = new Formie();
        formieInstance.initForms();

        document.addEventListener('onFormieInit', (event) => {
            // Fetch the Form Factory once it's been loaded
            let form = event.detail.formie;
            let formId = event.detail.formId;
            let formEl = document.getElementById(formId);

            // Refresh the necessary bits that are statically cached (CSRF inputs, captchas, etc)
            this.update(form, formId);

            if (formEl.getAttribute('data-form-submit-method') !== 'ajax') {
                formEl.addEventListener('onAfterFormieSubmit', (e) => {
                    //e.preventDefault();

                    this.update(form);
                });
            }
        });
    }

    update(form, formId) {
        form.refreshForCache(formId);
    }
}

export default new Forms();
